import { defineStore } from 'pinia'
import type { FetchError } from 'ofetch'
import type { UserDTO } from '~/types/User'
import { useSelectionStore } from '~/store/selection-store'
import type { Subscription } from '~/types/Subscription'
import type { WaitingListFormRequestBody } from '~/types/WaitingListFormRequestBody'
import type { Payment } from '~/types/Payment'
import type { PaymentStatusObject } from '~/types/PaymentStatusObject'
import type { Vehicle } from '~/types/Vehicle'
import type { Product } from '~/types/Product'
import type { PaymentIntent } from '~/types/PaymentIntent'
import type { InvoiceItem } from '~/types/InvoiceItem'
import { usePriceListStore } from '~/store/price-list-store'
import type { Appointment } from '~/types/Appointment'
import { useApi } from '~/composables/api'

export const useUserStore = defineStore('user', () => {
  const selectionStore = useSelectionStore()
  const priceListStore = usePriceListStore()
  const { activeCoupons, store } = storeToRefs(priceListStore)
  const { fetchWithoutAuth } = useApi()
  const user = ref<UserDTO>({
    first_name: '',
    last_name: '',
    phone: '',
    zip_code: '',
    street: '',
    house_number: '',
    country: '',
    city: '',
    email: '',
    password: '',
    codice_fiscale: '',
    id: ''
  })
  const token = ref<string | null>('')
  const subscription = ref<Subscription | null>(null)
  const stripePublishableKey = ref<string | null>(null)
  const payment = ref<Payment | null>(null)
  const paymentStatus = ref<PaymentStatusObject | null>(null)
  const paymentIntent = ref<PaymentIntent | null>(null)
  const invoiceItems = ref<Array<InvoiceItem> | null>(null)
  const appointment = ref<Appointment | null | undefined>(null)
  const isLoggedIn = computed((): boolean => !!user.value?.email && !!token.value)
  const subscriptionVehicle = computed((): Vehicle | undefined => subscription.value?.vehicles?.[0])
  const subscriptionProducts = computed((): Array<Product> => subscription.value?.products ?? [])
  const register = async function (innerUser: UserDTO): Promise<{ error: FetchError | undefined } | undefined> {
    const body: UserDTO = innerUser
    if (innerUser.country.toLowerCase() !== 'it') {
      delete body.codice_fiscale
    }
    const { data, error } = await fetchWithoutAuth<string>('/customers/register', {
      method: 'POST',
      body
    })
    if (!data || error) {
      return { error }
    }
    token.value = data
  }
  const getUser = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    const { data, error } = await fetch<UserDTO>('/me')
    if (!data || error) {
      return { error }
    }
    user.value = data
  }
  const login = async function (
    { email, password }:
    { email: string, password: string }
  ): Promise<{ error: FetchError | undefined } | undefined> {
    const { token: innerToken, error } = await fetchWithoutAuth('/auth', {
      method: 'POST',
      body: {
        as: 'customer',
        email,
        password
      }
    })
    if (!innerToken || error) {
      return { error }
    }
    token.value = innerToken
  }
  const forgotPassword = async function (email: string): Promise<{ error: FetchError | undefined } | undefined> {
    const { data, error } = await fetchWithoutAuth('/password-reset', {
      method: 'POST',
      body: {
        as: 'customer',
        email
      }
    })
    if (!data || error) {
      return { error }
    }
  }
  const logout = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    resetUser()
    resetSubscriptionAndPayment()
    const { data, error } = await fetch('/me/logout', {
      method: 'POST'
    })
    if (!data || error) {
      return { error }
    }
  }
  const resetUser = () => {
    user.value = {
      country: store.value?.location?.country
    } as UserDTO
    token.value = null
  }
  const confirmEmailCode = async function (code: string): Promise<{ error: FetchError | undefined } | undefined> {
    const { data, error } = await fetchWithoutAuth('/customers/email_validation', {
      method: 'POST',
      body: {
        email: user.value?.email,
        code
      }
    })
    if (!data || error) {
      return { error }
    }
  }
  const resendEmailConfirmationCode = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { data, error } = await fetchWithoutAuth('/customers/email_resend', {
      method: 'POST',
      body: {
        email: user.value?.email
      }
    })
    if (!data || error) {
      return { error }
    }
  }
  const subscribe = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    const {
      selectedProducts,
      selectedPickupDate,
      selectedPickupSlot,
      selectedStore,
      selectedVehicle,
      selectedSubscriptionPlan
    } = storeToRefs(selectionStore)
    const products = selectedProducts.value.map((product: Product) => ({
      id: product.id,
      quantity: 1
    }))
    const body = {
      pickup_date: selectedPickupDate.value ? selectedPickupDate.value.toDateString() : null,
      pickup_slot: selectedPickupSlot.value?.utc_date_time_string ? selectedPickupSlot.value?.utc_date_time_string : null,
      products,
      store_id: selectedStore.value?.id,
      vehicle_model_group_id: selectedVehicle.value?.id,
      vehicle_model_id: selectedVehicle.value?.id,
      subscription_plan_id: selectedSubscriptionPlan.value?.id,
      coupon: activeCoupons.value[0]?.id
    }
    selectedVehicle.value?.isGroup ? delete body.vehicle_model_id : delete body.vehicle_model_group_id
    const { data, error } = await fetch<Subscription>('/subscriptions/new-subscription', {
      method: 'POST',
      body
    })
    if (!data || error) {
      return { error }
    }
    subscription.value = data
  }
  const getSubscriptions = async (): Promise<{ error: FetchError | undefined } | undefined> => {
    const { fetch } = useApi()
    const { data, error } = await fetch<Array<Subscription>>(`/customers/${user.value?.id}/subscriptions`)
    if (!data?.length || error) {
      return { error }
    }
    const currentSubscriptions = data
      .filter((innerSubscription: Subscription) => innerSubscription.status === 'pending_activation' || innerSubscription.status === 'active')
    const currentSubscription = currentSubscriptions
      .reduce((acc: Subscription, curr: Subscription) => new Date(curr.created_at).getTime() > new Date(acc.created_at).getTime() ? curr : acc, currentSubscriptions[0] || undefined)
    if (!currentSubscription) {
      return
    }
    subscription.value = currentSubscription
  }
  const subscribeToWaitingList = async function (waitingListFormRequestBody: WaitingListFormRequestBody): Promise<{ error: FetchError | undefined } | undefined> {
    const { data, error } = await fetchWithoutAuth('/waiting-list', {
      method: 'POST',
      body: waitingListFormRequestBody
    })
    if (!data || error) {
      return { error }
    }
  }
  const getStripePublishableKey = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetchStripeKey } = useApi()
    const { data, error } = await fetchStripeKey(`/subscriptions/${subscription.value?.id}/stripe-publishable-key`)
    if (!data || error) {
      return { error }
    }
    stripePublishableKey.value = data
  }
  const createPayment = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    // TODO If no subscription is found display error
    const { data, error } = await fetch<Payment>(`/subscriptions/${subscription.value?.id}/new-payment`, {
      method: 'POST'
    })
    if (!data || error) {
      return { error }
    }
    payment.value = data
  }
  const cancelSubscription = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    if (!subscription.value?.id) {
      return
    }
    const { data, error } = await fetch(`/subscriptions/${subscription.value?.id}/abort`, {
      method: 'POST'
    })
    if (!data || error) {
      return { error }
    }
    subscription.value = null
  }
  const getPaymentIntent = async function ({ paymentId }: { paymentId: string }): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    const { data, error } = await fetch<PaymentIntent>(`/transactions/payment-intent/${paymentId}`)
    if (!data || error) {
      return { error }
    }
    paymentIntent.value = data
  }
  const getInvoiceItems = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    const { data, error } = await fetch<Array<InvoiceItem>>(`/invoices/${paymentIntent.value?.invoiceId}/invoiceItems`)
    if (!data || error) {
      return { error }
    }
    invoiceItems.value = data.sort((itemA, itemB) =>
      itemA.amount > itemB.amount ? -1 : 1
    )
  }
  const getAppointment = async function (): Promise<{ error: FetchError | undefined } | undefined> {
    const { fetch } = useApi()
    const { data, error } = await fetch<Array<Appointment>>('/customers/me/appointments')
    if (!data || error) {
      return { error }
    }
    appointment.value = data.find((innerAppointment: Appointment) => innerAppointment.appointment_type_name === 'Pickup' && innerAppointment.status === 'active')
  }

  const resetSubscriptionAndPayment = () => {
    subscription.value = null
    payment.value = null
    paymentStatus.value = null
    stripePublishableKey.value = null
    paymentIntent.value = null
    invoiceItems.value = null
    appointment.value = null
  }

  return {
    user,
    subscription,
    payment,
    paymentStatus,
    isLoggedIn,
    stripePublishableKey,
    token,
    subscriptionVehicle,
    subscriptionProducts,
    paymentIntent,
    invoiceItems,
    appointment,
    register,
    getUser,
    login,
    confirmEmailCode,
    resendEmailConfirmationCode,
    forgotPassword,
    logout,
    resetUser,
    subscribe,
    subscribeToWaitingList,
    getSubscriptions,
    getStripePublishableKey,
    createPayment,
    cancelSubscription,
    getPaymentIntent,
    getInvoiceItems,
    getAppointment,
    resetSubscriptionAndPayment
  }
}, {
  persist: {
    paths: ['user', 'token']
  }
})
